import { axiosConfig } from './axios-config.js'

export const message = {
  getHistoryFromAgency: (id, token, additionalParams = {}) => {
    return axiosConfig.get('/api/messages/agency/history', { headers: token.headers, params: additionalParams })
  },

  markMessageAsRead: (id, token, additionalParams = {}) => {
    return axiosConfig.get('/api/messages/agency/reads', { headers: token.headers, params: additionalParams })
  },

  sendFromAgency: (data, token) => axiosConfig.post('/api/messages/agency', data, token),

  online: (data, token) => axiosConfig.post('/api/messages/agency/online', data, token),

  reads: (data, token) => axiosConfig.post('/api/messages/agency/reads', data, token),

  sendImage: (data, token) => axiosConfig.post('/api/image', data, token)
}
