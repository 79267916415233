<!-- AnnouncementModal.vue -->
<template>
  <div class="modal fade cms-style" tabindex="-1" id="announcementModal" ref="announcementModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ announcement.title }}</h5>
          <button
            type="button"
            class="btn-close btn-close-white"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body text-center announcement-content" style="word-wrap: break-word">
          <p>{{ announcement.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { Modal } from 'bootstrap'
import { announcement } from '@/http/api/announcement.js'

export default {
  computed: {
    ...mapState({
      showAnnouncementModal: state => state.showAnnouncementModal,
      announcement: state => state.announcement,
      token: state => state.token
    })
  },
  mounted () {
    this.bootstrap_modal = new Modal(this.$refs.announcementModal, {
      keyboard: false
    })

    this.$refs.announcementModal.addEventListener('hidden.bs.modal', () => {
      this.closeAnnouncementModal()
    })
  },
  watch: {
    showAnnouncementModal (newValue) {
      const vm = this

      if (newValue) {
        this.bootstrap_modal.show()
        announcement.updateAllNotifiedAt({}, vm.token).then(res => {
        })
      } else {
        this.bootstrap_modal.hide()
      }
    }
  },
  methods: {
    ...mapMutations(['closeAnnouncementModal']),
    closeModal () {
      this.closeAnnouncementModal()
      this.bootstrap_modal.hide()
    }
  }
}
</script>

<style lang="scss">
.announcement-content {
  white-space: break-spaces;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
