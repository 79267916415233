<template>
  <div class="user-cms">
    <Navbar/>
    <router-view :key="new Date().getTime()"/>
    <announcement-modal></announcement-modal>

  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue'
import AnnouncementModal from '@/components/AnnouncementModal.vue'

export default {
  name: 'UserCms',
  components: { Navbar, AnnouncementModal }
}
</script>

<style lang="scss">
.user-cms{
  background-color: $background-color;
  min-height: 100vh;
}
</style>
